<!-- 商品详情 -->
<template>
	<div :class="themeClass" class="max-width1100 margin-tb-20">
		<div class="flex-row margin-b-5">
			您的位置：
			<breadcrumb></breadcrumb>
		</div>
		<div class="flex-colum">
			<div class="flex-row top-box">
				<div class="box-left margin-r-20">
					<div class="outline-imgs">
						<div class="position-relative border-F2F2F2" style="width: 347px;height: 347px;" v-if="videoShow">
							<div class="position-absolute iconfont icon-guanbi font-size30 font-weight700 delete-btn pointer" @click="videoShow = false"></div>
							<video ref="myvideo" controls="controls" :autoplay="videoShow?'autoplay':''" style="width: 100%;height: 100%;">
								<source :src="listData.goodsVideos ? listData.goodsVideos[0].fPath == '' ? null : listData.goodsVideos[0].fullFPath : null" />
							</video>
						</div>
						<div class="outline-img position-relative" v-else>
							<piczoom :url="showPicUrl.fPicturePath" :scale="3"></piczoom>
							<div v-if="listData.goodsVideos && listData.goodsVideos[0].fPath != ''" class="position-absolute font-size48 font-color-fff play-img" style="bottom: 50px;left: 43.5%;opacity: 1;"
							 @click="videoShow = true"></div>
						</div>
						<div class="outline-piclist">
							<div class="left-arrow margin-t-20" v-if="picurl.length > 1" @click="leftImg">◀</div>
							<div class="right-arrow margin-t-20" v-if="picurl.length > 1" @click="rightImg">▶</div>
							<div class="piclist-box" ref="piclist">
								<div class="piclist-img" v-for="(value,index) in picurl" :key="index" :class="{'active':showPicUrl == value}"
								 @click="imgClick(value,index)">
									<img :src="value.fPicturePath" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="box-right flex-colum">
					<div class="flex-row margin-b-5">
						<div class="tag-box font-size12 font-color-fff radius2 margin-r-5" v-if="listData.fShopTypeID == 1">
							{{listData.fShopTypeName}}
						</div>
						<div class="font-size18 font-weight700" style="line-height: 18px;">{{listData.fGoodsFullName}}</div>
					</div>
					
					<!-- 活动条幅 -->
					<div v-if="hasActivity" class="activity-box flex-row-space-between  font-color-fff font-size18 margin-t-15" 
					style="background: #E1251A;border-radius: 10px 10px 0 0 ;">
						<div style="margin: 0 13px;width:100%;border-bottom:1px dashed #fff" class="flex-row-space-between">
							<div class="flex-row-align-center">
								<div class="font-weight700">
									{{activityInfo.fActivityName}}
								</div>
							</div>
							<div class="flex-row-align-center font-size12">
								<span class="margin-r-10 font-size16">{{showactivitytext}}</span>
								<countDowm :remainTime="usedTime" :showBold='false' @countDownEnd="countDownEnd" bgColor="#ffffff"></countDowm>
							</div>
						</div>
					</div>
					<div class="commodityInformation-box padding-lr-13 padding-tb-5 font-size13 bg-FFFAF9 font-color-666"
					style="background: #E1251A;border-radius: 0 0 10px 10px ;">
						<div class="flex-row-space-between-center">
							<div class="flex-row-space-between-center">
								<div class="tatle-box font-color-fff">
									现价
								</div>
								<span class="margin-r-10 font-size25 font-weight700 font-color-fff">
									{{priceRange}}<span class="font-size13 font-weight400">积分</span>
								</span>
							</div>
						</div>
					</div>
					<div class="padding-t-13 padding-lr-13 font-color-666 commodityInformation-box">

						<div class="flex-colum font-size13">
							<div class="flex-row margin-b-30">
								<div class="font-color-1e1e1e padding-tb-5 tatle-box flex-row-space-between-center">
									<div>
										数量 <span v-if="listData.fUnitName">({{listData.fUnitName}})</span>
									</div>
								</div>
								<div class="flex-row-wrap  width-fill">
									<el-input-number v-model="numValue" :min="minNumValue" :max="99999" :precision="precisionNumValue" :step="stepNumValue"
									 @change="handleChange" label="描述文字" size="mini" @focus="getInputFocus($event)"></el-input-number>
								</div>
							</div>
							<div class="flex-row margin-b-50">
								<div class="btn-box radius30 text-center margin-r-10 bg-FF9900 font-color-fff pointer font-size18 font-weight700" @click="buyNowClick">
									立即兑换
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom-box font-size13 flex-row">
				<div class="b-box-left margin-r-10">
					<div class="left-top flex-colum border-F2F2F2 margin-b-10 padding-13">
						<div class="flex-row margin-b-5">
							<div class="img-box border-F2F2F2 radius10 padding-5 margin-r-5">
								<img :src="listData.fShopLogoPath" alt="" class="img-style">
							</div>
							<div class="flex-colum width-fill">
								<div class="font-weight700 font-size14 twoline-show-hide margin-b-5">{{listData.fShopName}}</div>
								<div class="shopStarRating-box radius20 flex-row bg-F2F2F2 text-center font-size12 padding-lr-10 margin-b-5">
									<span class="margin-r-5">
										店铺星级
									</span>
									<div class="margin-tb-auto">
										<el-rate v-model="starLevel" :colors="['#FF3300','#FF3300','#FF3300']" :disabled-void-color="'#e4e4e4'"
										 disabled></el-rate>
									</div>
								</div>
								<div class="flex-row-space-between-center margin-b-5">
									<div>{{focusOnQuantity}}关注</div>
									<div class="bg-F8F9FE radius30 color-theme pointer follow-box text-center" @click="followCreateClick" v-if="followShow">+关注</div>
									<div class="bg-F8F9FE radius30 color-theme pointer follow-box text-center" @click="followDeleteClick" v-else>已关注</div>
								</div>
							</div>
						</div>

						<div class="flex-row-space-between">
							<div v-if="fRulObj !=null" class="shopBtn-box text-center border-theme radius30 pointer" @click="contactServiceClick">
								<span class="iconfont icon-kefu1 margin-r-5 color-theme font-size17"></span>
								联系客服
							</div>
							<div class="shopBtn-box text-center border-theme radius30 pointer" @click="enterTheStore(listData.fShopUnitID)">
								<span class="iconfont icon-dianpu2 margin-r-5 color-theme"></span>
								进店逛逛
							</div>
						</div>
					</div>
					<div class="left-bottom bg-F2F4FD box-sizing padding-13 flex-row-between-wrap">
						<div class="pointer" :class="index+1 == 1?'margin-b-10':''" v-for="(item,index) of shopMerchandise" :key="index"
						 @click="hotGoods(item)">
							<div class="img-box1 bg-fff radius10 flex-row-center-center">
								<img :src="item.goods.fPicturePath" alt="" class="img-style1 margin-auto">
							</div>
							<div class="title-box hide-text margin-t-10 font-size12">
								{{item.goods.fGoodsFullName}}
							</div>
							<div class="title-box hide-text margin-t-10 font-size14 font-color-FF0000 font-weight700">
								<span class="font-size12">￥</span>{{item.fMinGoodsPrice|NumFormat}}<span class="font-size12">元/{{listData.fUnitName}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="b-box-right">
					<div class="left-middle flex-colum border-F2F2F2 margin-b-10 padding-13" v-if="ruledetails.length>0">
						<div class="flex-row margin-b-10">
							<img src="@/assets/imgs/rules.png" alt="" style="width:28px;height:28px;">
							<div class="font-weight700  font-size18 margin-l-10" style="color:#0173FE">店铺规则</div>
							<div class="font-weight400 font-size18 margin-l-10" style="color:#0173FE">SHOP&nbsp;RULES</div>
						</div>
						<div class="padding-10 bg-fff radius5" style="box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.10); ">
							<div v-for="(item,index) of ruledetails" :key="index">
								<div class="flex-colum">
									<div v-if="item.fIsTitle==2" class="color-theme font-weight700" style="line-height: 22px; color: #0173FE;">
										{{item.fValue}}
									</div>
									<div v-if="item.fIsTitle==1" class="font-size12 font-weight700" style="line-height: 18px;color: #000000;">
										{{item.fValue}}
									</div>
									<div v-if="item.fIsTitle==0" class="font-size12" style="line-height: 18px;">
										{{item.fValue}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="">
						<el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
							<el-tab-pane v-for="(item,index) of tabData" :key="index" :label="item.fGoodsDetailClass" :name="item.fGoodsDetailClassCode">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="item.fContent != null" :html="item.fContent" :styleRule="rule" />
								</div>
							</el-tab-pane>
							<!-- <el-tab-pane label="规格参数" name="second">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
								</div>
							</el-tab-pane>
							<el-tab-pane label="使用范围" name="third">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
								</div>
							</el-tab-pane>
							<el-tab-pane label="售后保障" name="fourth">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
								</div>
							</el-tab-pane> -->
							<!-- <el-tab-pane label="全部评价" name="fifth">
								<div class="flex-colum">
									<div class="evaluateTitle-box padding-lr-20 border-F2F2F2 box-sizing flex-row-align-center font-color-333">
										<div class="flex-colum margin-r-30">
											<div class="font-size12">
												好评度
											</div>
											<div class="font-size25 font-weight700 color-theme">
												{{highPraise}}
											</div>
										</div>
										<div class="font-size12 flex-row">
											<div class="whole text-center radius20 margin-r-10 bg-F1F3FE border-F1F3FE" :class="evaluateBtnData[0].checked?'border-theme color-theme':''"
											 @click="evaluateBtnClick(evaluateBtnData[0].name)">
												{{evaluateBtnData[0].name}}
											</div>
											<div class="evaluate-btn padding-lr-15 text-center radius20 margin-r-10 bg-F1F3FE border-F1F3FE" :class="item.checked?'border-theme color-theme':''"
											 v-for="(item,index) of evaluateBtnData" :key="index" @click="evaluateBtnClick(item.name)">
												{{item.name}}
											</div>
										</div>
									</div>
									<div class="padding-lr-20 margin-b-30">
										<div class="border-bottom-F2F2F2 padding-tb-10" v-for="(item,index) of evaluateListData" :key="index">
											<div class="flex-row-space-between margin-b-10">
												<div class="flex-row">
													<div class="img-box2 bg-F2F2F2 text-center margin-r-5">
														<img :src="item.img" class="img-style2">
													</div>
													<div class="flex-column">
														<span class="">
															{{item.name}}
														</span>
														<div class="margin-tb-auto">
															<el-rate v-model="value1" :colors="['#FF3300','#FF3300','#FF3300']" :disabled-void-color="'#e4e4e4'"
															 disabled></el-rate>
														</div>
													</div>
												</div>
												<div class="margin-t-auto font-color-999">
													{{item.date}}
												</div>
											</div>
											<div class="flex-row-space-between font-color-999">
												<div class="">
													<span class="margin-r-10">
														{{item.wuliao}}
													</span>
													{{item.num}}吨
												</div>
												<div>
													<span class="margin-r-5" :class="thumbActive?'color-theme':''">{{item.num}}</span>
													<span class="iconfont icon-dianzan" :class="thumbActive?'color-theme':''" @click="thumbClick"></span>
												</div>
											</div>
										</div>
									</div>
									<div class="flex-row-center-flex-end">
										<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
										 :pagesize="pagesize" :total="total">
										</pagination>
									</div>
								</div>
							</el-tab-pane> -->
						</el-tabs>
					</div>
				</div>
			</div>
		</div>

		<el-dialog v-dialogDrag title="议价申请" :visible.sync="showModel" width="30%" :before-close="handleClose"
		 :close-on-click-modal="false" :close-on-press-escape="false">
			<div ref="dialogBox" class="dialogScroll">
				<el-form ref="form" :rules="rules" :model="form" :label-width="formLabelWidth" label-position="right">
					<el-form-item label="询价单号">
						<el-input v-model="form.danhao" placeholder="请填写询价单号" />
					</el-form-item>
					<el-form-item label="价格开始日期">
						<el-date-picker type="date" placeholder="请选择价格开始日期" v-model="form.startDate" style="width: 100%;"></el-date-picker>
					</el-form-item>
					<el-form-item label="使用地点" prop="didian">
						<el-input v-model="form.didian" placeholder="请填写使用地点" />
					</el-form-item>
					<el-form-item label="原因" prop="yuanyin">
						<el-input v-model="form.yuanyin" placeholder="请填写原因" />
					</el-form-item>
					<el-form-item label="备注" prop="fRemark">
						<el-input v-model="form.fRemark" placeholder="请填写备注" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog
		  id='ask'
		  :visible.sync="askdialogVisible"
		  title="全额积分兑换规则"
		  width="30%"
		  top='50vh'
		  :before-close="askhandleClose">
		  <div ref="dialogBox"  class="askdialogScroll">
			  <div style="line-height: 20px;font-size:15px;margin-bottom:5px;color:#606266" v-for="(item, index) of ruleData" >{{index+1}}，{{item.fUseDescribe}}</div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button  style="cursor: pointer;"   @click="askdialogVisible = false">取 消</el-button>
		    <el-button  style="cursor: pointer;" type="primary"   @click="atOncebuy">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue'
	import countDowm from '@/components/countDown/countDown.vue'
	import RichTextStyle from "@/components/richtext/RichTextStyle";
	import pagination from '@/components/pagination/pagination.vue'
	import selectArea from '@/components/selectArea/selectArea.vue'
	import piczoom from '@/components/vue-piczoom/'
	export default {
		components: {
			breadcrumb,
			countDowm,
			RichTextStyle,
			pagination,
			selectArea,
			piczoom
		},
		data() {
			return {
				ruleData:[],
				askdialogVisible:false,
				videoShow: false, //商品视频介绍是否展示
				collectCouponsPopupData: [], //优惠券信息
				addressActiveName: 'first', //默认显示哪个tab
				addressList: [], //地址数据
				starLevel: null, //店铺星级
				fGoodsID: 1, //当前商品id
				fDeliveryID: '', //当前商品选中的提货方式id
				fGovernmenAreaID: ['', '', '', ''], //当前区域id
				numValue: null, //商品数量
				minNumValue: null, //计数器可输入的最小值
				stepNumValue: null, //计数器步长
				precisionNumValue: null, //计数器精度
				showPicUrl: {
					fPicturePath: ''
				}, //商品主图
				picurl: [], //商品左右图片
				showPicInx: 0, // 当前选中商品图片下标
				priceRange: '', //价格区间
				listData: {}, //商品详情数据
				collection: false, //是否收藏
				evaluateNum: '100+',
				highPraise: '90%',
				evaluateListData: [{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					},
					{
						img: require('@/assets/imgs/GoldCoinMall/phone.png'),
						name: '匿名评价',
						date: '2020-10-20',
						wuliao: '阜康天山普硅32.5',
						num: '50'
					}
				],
				countDownShow: true,
				saleAttributeList: [], //商品销售属性

				otherAttributeList: [], //商品其他销售属性

				deliveryMethodList: [], //提货方式

				warehouseAddress: '', //仓库地址

				attributeGoods: [], //判断商品是否存在如不存在则显示无货接口除了其他属性以外属性参数数组
				otherattributeID: '', //判断商品是否存在如不存在则显示无货其他属性参数
				isItAvailable: true, //是否有货

				focusOnQuantity: '', //店铺多少人关注
				followShow: false, //是否关注

				ismakeIntegral: '', //店铺产不产生积分
				ruledetails:'',
				// shopRulesData: [{
				// 		title: '积分规则',
				// 		outerList: [{
				// 				listTitle: '积分获取：',
				// 				list: [{
				// 						rule: '首次关注店铺送X积分；'
				// 					}, {
				// 						rule: '任务送积分（详情见任务集市）；'
				// 					},
				// 					// {
				// 					// 	rule: '每日首次订单分享送X积分；'
				// 					// },
				// 					// {
				// 					// 	rule: '每日首次任务分享送X积分；'
				// 					// },
				// 					{
				// 						rule: '每笔订单评价送X积分；'
				// 					}, {
				// 						rule: '提货出厂送积分，该笔提货对应的货款金额每满100元送1积分（如有积分活动，详见店铺通知）；'
				// 					}, {
				// 						rule: '其它赠送积分。'
				// 					}
				// 				]
				// 			},
				// 			{
				// 				listTitle: '积分使用：',
				// 				list: [{
				// 					rule: '购买本店铺商品使用；'
				// 				}, {
				// 					rule: '兑换平台金币使用；'
				// 				}, {
				// 					rule: '店铺其它活动使用。'
				// 				}]
				// 			}
				// 		]
				// 	},
				// 	{
				// 		title: '订单有效期',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 				rule: '本店铺订单有效期为自生效之日起X个自然日。'
				// 			}]
				// 		}]
				// 	},
				// 	{
				// 		title: '发票',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 					rule: '本店铺每月扎账时间为XX日XX点；'
				// 				},
				// 				{
				// 					rule: '本店铺发票开具时间为每月XX号统一开具；'
				// 				},
				// 				{
				// 					rule: '发票递送方式：⑴、客户自取。⑵、发票开具后X个自然日内邮寄。⑶、电子发票开具后发送至预留邮箱。'
				// 				}
				// 			]
				// 		}]
				// 	},
				// 	{
				// 		title: '检验报告单',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 					rule: '本店铺提供：电子检验报告单/纸质检验报告单；'
				// 				},
				// 				{
				// 					rule: '电子检验报告单由会员在本平台自行申请获取；'
				// 				},
				// 				{
				// 					rule: '纸质检验报告单需会员与本店铺客服联系获取。'
				// 				}
				// 			]
				// 		}]
				// 	},
				// 	{
				// 		title: '售后',
				// 		outerList: [{
				// 			listTitle: '',
				// 			list: [{
				// 					rule: '售后服务电话：XXXX-XXXXXXX/1XXXXXXXXXX'
				// 				},
				// 				{
				// 					rule: '售后服务时间：工作日/每日  XX:XX-XX:XX'
				// 				}
				// 			]
				// 		}]
				// 	},
				// ], //店铺规则

				value1: 2,
				shopMerchandise: [], //店铺热销商品

				tabData: [], //厂家，规格，适用范围，售后保障
				activeName: '', //选中哪个
				content: '',
				rule: [], //富文本规则
				thumbActive: false, //评价是否点赞

				total: 1000, //总条数
				pagesize: 10, //每页显示条目个数
				currentPage: 1, //当前页

				showModel: false, //打开议价申请
				form: {
					danhao: '',
					startDate: '',
					didian: '',
					yuanyin: '',
					fRemark: ''
				},
				rules: {
					didian: [{
						required: true,
						message: '地点不能为空',
						trigger: 'blur'
					}],
				},
				formLabelWidth: '100px', //label宽
				evaluateBtnData: [{
						name: '全部',
						checked: true
					},
					{
						name: '好评60+',
						checked: false
					},
					{
						name: '中评',
						checked: false
					},
					{
						name: '差评',
						checked: false
					},
					{
						name: '差评',
						checked: false
					},
					{
						name: '差评',
						checked: false
					}
				],
				hasActivity: false, //是否有活动
				activityInfo: {}, //活动内容
				usedTime: 0, //倒计时的毫秒数
				showactivitytext: "",
				fRulObj:null
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		// activated() {
		// 	this.$refs.piclist.style.left = 48 + "px";
		// 	////console.log('this.area', this.area);
		// 	this.fGoodsID = this.$route.query.fGoodsID;
		// 	this.getGoodsBaseInfo();
		// },
		mounted() {
			this.$refs.piclist.style.left = 48 + "px";
			////console.log('this.area', this.area);
			this.fGoodsID = this.$route.query.fGoodsID;
			this.getGoodsBaseInfo();
			this.getGoodsActivityInfo();
		},
		methods: {
			//小图左边三角点击事件
			leftImg() {
				if (this.showPicInx > 0 && this.showPicInx <= this.picurl.length) {
					this.showPicInx = this.showPicInx - 1;
					this.showPicUrl = this.picurl[this.showPicInx];
					if (
						this.showPicInx == 0 ||
						this.showPicInx == 1 ||
						this.showPicInx == 2 ||
						this.showPicInx == 3
					) {
						this.$refs.piclist.style.left = 38 + "px";
					} else {
						this.$refs.piclist.style.left =
							"-" + 125 * (this.showPicInx - 3) + "px";
					}
				}
			},
			//小图右边三角点击事件
			rightImg() {
				if (this.showPicInx >= 0 && this.showPicInx < this.picurl.length - 1) {
					this.showPicInx = this.showPicInx + 1;
					this.showPicUrl = this.picurl[this.showPicInx];
					if (this.showPicInx == 0 || this.showPicInx == 1 || this.showPicInx == 2 || this.showPicInx == 3) {
						this.$refs.piclist.style.left = 20 + "px";
					} else {
						////console.log('11');
						this.$refs.piclist.style.left =
							"-" + 130 * (this.showPicInx - 3) + "px";
					}
				}
			},
			//图片点击事件
			imgClick(val, index) {
				this.showPicUrl = val;
				this.showPicInx = index;
			},
			// 倒计时结束事件
			countDownEnd() {
				this.$router.push("/");
			},
			//获取当前商品是否有活动
			getGoodsActivityInfo() {
				this.ApiRequestPost('api/mall/ebactivity/point-swap-goods-rule-bill/get-cuurent-platform', {})
					.then(result => {
							console.log('获取当前商品活动', result);
							if (result.obj.isShow == 1) { //有商品活动
								// this.hasActivity = true;
								this.activityInfo = result.obj.dto
								let beforeshow = new Date(this.activityInfo.fRemindTime).getTime(); //活动开始展示时间
								let start = new Date(this.activityInfo.fBeginTime).getTime(); //活动开始时间
								let end = new Date(this.activityInfo.fEndTime).getTime(); //活动结束时间
								let now = new Date().getTime();

								if (now < beforeshow) {
									//活动未开始
									this.hasActivity = false
									return;
								} else {
									this.hasActivity = true
								}
								if (now >= beforeshow && now < start) {
									console.log(start - now)
									this.showactivitytext = "距离活动开始："
									this.usedTime = (start - now)
								}
								if (now > end) {
									console.log('活动已结束')
									this.hasActivity = false
								}
								if (now >= start && now < end) {
									console.log(end - now)
									this.showactivitytext = "距离活动结束："
									this.usedTime = (end - now)

								}
							} else {
								this.hasActivity = false
							}
						},
						rej => {}
					)
			},




			//根据商品ID查询当前商品信息
			getGoodsBaseInfo() {
				this.ApiRequestPostNOMess('api/mall/ebactivity/point-swap-goods-price/get-goods-base-info', {
						id: this.fGoodsID
					})
					.then(result => {
							this.videoShow = false;
							// console.log('根据商品ID查询当前商品信息', result);
							this.picurl = [];
							this.listData = result.obj;
							/* 获取好评星级 */
							let sums = result.obj.fEvaluationScore;
							if (sums != null) {
								this.starLevel = sums / 20;
							}
							// this.showPicUrl = this.getUploadFileURL(result.obj.fPicturePath);
							this.showPicUrl.fPicturePath = result.obj.fPicturePath;
							result.obj.goodsPictures.unshift(this.showPicUrl);
							this.picurl = result.obj.goodsPictures;
							console.log(this.picurl,'查看需要的图片')
							// this.picurl.push(this.showPicUrl)
							this.numValue = result.obj.goodsSaleAttribute.fMinSaleAmount;
							this.minNumValue = result.obj.goodsSaleAttribute.fMinSaleAmount //计数器可输入的最小值
							this.stepNumValue = result.obj.goodsSaleAttribute.fAdjustmentRange; //计数器步长
							this.precisionNumValue = result.obj.goodsSaleAttribute.fPrecision; //计数器精度
							////console.log('this.showPicUrl', this.showPicUrl);
							////console.log('this.picurl', this.picurl);

							this.getIsmakeIntegral(); //获取本店铺是否产生积分
							// this.getFirstFocus(); //获取首次关注店铺送多少积分
							// this.getFristOrderShard(); //获取每日首次订单分享送多少积分
							// this.getEverydayFristShard(); //获取每日首次任务分享送多少积分
							// this.getEverydayEvaluation(); //获取每笔订单评价送多少积分
							this.getIntegral(); //获取首次关注店铺送多少积分/获取每笔订单评价送多少积分
							this.getTheNaturalDay(); //获取本店铺订单有效期
							this.getShopSettleDay(); //获取店铺扎帐日
							this.getShopMakeOpenBill(); //获取本店铺发票开具时间为每月多少号
							this.getShopMakeOpenBillPostday(); //获取店铺发票开具后多少个自然日内邮寄
							this.getAfterSalesserviceTelephone(); //获取售后服务电话
							this.getAfterSalesserviceTime(); //获取售后服务时间

							this.getPointSwapGoodsPrice(); //获取积分换购价格
							this.getHotGoodsByFShopUnitIDList(); //获取当前店铺热销商品
							this.getByGoodsidList(); //获取商品介绍（厂家，规格，适用范围，售后保障）
							this.getByShopunitID(); //获取是否关注店铺
							this.storeRules();
							this.getByShop()
						},
						rej => {}
					)
			},

			//店铺规则开始
			storeRules(){
				this.ApiRequestPostNOMess('api/mall/ebshop/baseinfo/get-rule-by-shopid',{
					id: this.listData.fShopUnitID
				}).then(
				  res => {
					  this.ruledetails = res.obj
            console.log(this.ruledetails,'店铺跪着')
					  // console.log(777777777777,this.rule)
				  }
				)
			},
			//获取本店铺是否产生积分
			getIsmakeIntegral() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-ismake-integral', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取本店铺是否产生积分', res);
					this.ismakeIntegral = res ? '产生' : '不产生';
				}, error => {

				});
			},
			//获取首次关注店铺送多少积分/获取每笔订单评价送多少积分
			getIntegral() {
				this.ApiRequestPostNOMess('api/mall/ebactivity/first-focus-shop-rule/get-shop-rule', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取首次关注店铺送多少积分', res.obj.firstFocusShopRule);
					console.log('获取每笔订单评价送多少积分', res.obj.fAppraiseOrderShopRule);
					// this.shopRulesData[0].outerList[0].list[0].rule = '首次关注店铺送' + res.obj.firstFocusShopRule + '积分；';
					// this.shopRulesData[0].outerList[0].list[2].rule = '每笔订单评价送' + res.obj.fAppraiseOrderShopRule + '积分；';
				}, error => {

				});
			},
			//获取首次关注店铺送多少积分
			// getFirstFocus() {
			// 	this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-first-focus', {
			// 		fShopID: this.listData.fShopUnitID
			// 	}).then(res => {
			// 		console.log('获取首次关注店铺送多少积分', res);
			// 		this.shopRulesData[0].outerList[0].list[0].rule = '首次关注店铺送' + res + '积分；';
			// 	}, error => {

			// 	});
			// },
			//获取每日首次订单分享送多少积分
			getFristOrderShard() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-frist-order-shard', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取每日首次订单分享送多少积分', res);
					// this.shopRulesData[0].outerList[0].list[2].rule = '每日首次订单分享送' + res + '积分；';
				}, error => {

				});
			},
			//获取每日首次任务分享送多少积分
			getEverydayFristShard() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-everyday-first-shard', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取每日首次任务分享送多少积分', res);
					// this.shopRulesData[0].outerList[0].list[3].rule = '每日首次任务分享送' + res + '积分；';
				}, error => {

				});
			},
			//获取每笔订单评价送多少积分
			// getEverydayEvaluation() {
			// 	this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-everyorder-evaluation', {
			// 		fShopID: this.listData.fShopUnitID
			// 	}).then(res => {
			// 		console.log('获取每笔订单评价送多少积分', res);
			// 		this.shopRulesData[0].outerList[0].list[2].rule = '每笔订单评价送' + res + '积分；';
			// 	}, error => {

			// 	});
			// },
			//获取本店铺订单有效期
			getTheNaturalDay() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-the-natural-day', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取本店铺订单有效期', res);
					// this.shopRulesData[1].outerList[0].list[0].rule = '本店铺订单有效期为自生效之日起' + res + '个自然日。';
				}, error => {

				});
			},
			//获取店铺扎帐日
			getShopSettleDay() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-settle-day', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取店铺扎帐日', res);
					this.getShopSettleTime(res);
				}, error => {

				});
			},
			//获取店铺扎帐时间
			getShopSettleTime(day) {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-settle-time', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取店铺扎帐时间', res);
					// this.shopRulesData[2].outerList[0].list[0].rule = '本店铺每月扎账时间为' + day + '日' + res + '点；';
				}, error => {

				});
			},
			//获取本店铺发票开具时间为每月多少号
			getShopMakeOpenBill() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-make-open-bill', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取本店铺发票开具时间为每月多少号', res);
					// this.shopRulesData[2].outerList[0].list[1].rule = '本店铺发票开具时间为每月' + res + '号统一开具；';
				}, error => {

				});
			},
			//获取店铺发票开具后多少个自然日内邮寄
			getShopMakeOpenBillPostday() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-shop-make-open-bill-postday', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取店铺发票开具后多少个自然日内邮寄', res);
					// this.shopRulesData[2].outerList[0].list[2].rule = '发票递送方式：⑴、客户自取。⑵、发票开具后' + res +
					// 	'个自然日内邮寄。⑶、电子发票开具后发送至预留邮箱。';
				}, error => {

				});
			},
			//获取售后服务电话
			getAfterSalesserviceTelephone() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-after-salesservice-telephone', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取售后服务电话', res);
					// this.shopRulesData[4].outerList[0].list[0].rule = '售后服务电话：' + res;
				}, error => {

				});
			},
			//获取售后服务时间
			getAfterSalesserviceTime() {
				this.ApiRequestPostNOMess('api/mall/ebbase/para-value/get-after-salesservice-time', {
					fShopID: this.listData.fShopUnitID
				}).then(res => {
					console.log('获取售后服务时间', res);
					// this.shopRulesData[4].outerList[0].list[1].rule = '售后服务时间：工作日/每日  ' + res;
				}, error => {

				});
			},
			//店铺规则结束

			//获取积分换购价格
			getPointSwapGoodsPrice() {
				this.ApiRequestPostNOMess('api/mall/ebactivity/point-swap-goods-price/get-by-fgoodsid', {
					id: this.fGoodsID
				}, ).then(res => {
					this.priceRange = res.obj.fPointPrice;
				}, error => {

				});
			},
			//获取厂家，规格，适用范围，售后保障
			getByGoodsidList() {
				this.ApiRequestPostNOMess('api/mall/goods/goods-detail/get-by-goodsid-list', {
						goodsID: this.fGoodsID,
						fAppType: 1
					})
					.then(result => {
							////console.log('厂家，规格，适用范围，售后保障', result);
							if (result.obj.length <= 0) {

							} else {
								result.obj.forEach((item, i) => {
									if (i == 0) {
										item.checked = true;
										this.content = item.fContent;
									} else {
										item.checked = false;
									}
								});
								this.tabData = result.obj;
								this.activeName = this.tabData[0].fGoodsDetailClassCode;
							}
						},
						rej => {}
					)
			},
			//获取当前商品是否收藏
			getStartStatus() {
				this.ApiRequestPostNOMess('api/mall/auxiliary/goods-collect/get-start-status', {
						id: this.fGoodsID
					})
					.then(result => {
							// ////console.log('获取当前商品是否收藏', res);
							this.collection = result.obj;
						},
						rej => {}
					)
			},
			//用户可对商品进行收藏和取消收藏
			star() {
				this.ApiRequestPostNOMess('api/mall/auxiliary/goods-collect/star', {
						id: this.fGoodsID
					})
					.then(result => {
							// ////console.log('用户可对商品进行收藏和取消收藏', res);
							this.getStartStatus();
						},
						rej => {}
					)
			},

			//获取店铺热销商品
			getHotGoodsByFShopUnitIDList() {
				this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-hot-goods-by-fShopUnitID-list', {
						id: this.listData.fShopUnitID
					})
					.then(result => {
							//console.log('获取店铺热销商品', result);
							// for (let item of result.obj) {
							//   item.goods.fPicturePath = this.getUploadFileURL(item.goods.fPicturePath);
							// }
							if (result.obj.length <= 4) {
								this.shopMerchandise = result.obj;
							} else {
								this.shopMerchandise = result.obj.slice(0, 4);
							}
						},
						rej => {}
					)
			},
			//计数器事件
			handleChange(value) {
				console.log('this.numValue', this.numValue);
				console.log('this.stepNumValue', this.stepNumValue);
				if (!this.numValue) {
					this.numValue = 0
				}
				if (this.numValue % this.stepNumValue != 0 && this.stepNumValue != 0) {
					this.numValue = (this.numValue / this.stepNumValue).toFixed(0) * this.stepNumValue;
				}
				console.log('this.numValue', this.numValue);
			},
			//计数器获得焦点全选内容
			getInputFocus(event) {
				event.currentTarget.select();
			},
			acquireRule(){
				this.ruleData=[]
				this.ApiRequestPostNOMess('api/mall/ebactivity/point-swap-goods-rule-bill/get-cuurent-platform')
					.then(result => {
						if(result.obj.dto.frule){
							this.ruleData = result.obj.dto.frule
						}
						},
						rej => {}
					)
			},
			acquireRuleShop(){
				this.ruleData=[]
				this.ApiRequestPostNOMess('api/mall/ebactivity/point-swap-goods-rule-bill/get-by-shopid',{
					id:this.listData.fShopUnitID
				})
					.then(result => {
						if(result.obj.dto.frule){
							this.ruleData = result.obj.dto.frule
						}
						},
						rej => {}
					)
			},
			//询问得是要立即购买
			buyNowClick() {
				this.askdialogVisible = true
				//获取规则的数据
				if(this.listData.fShopUnitID == '5'){
					this.acquireRule()
				}else{
					this.acquireRuleShop()
				}
				
			},
			//点击确定要立即购买
			atOncebuy(){
				let obj = [];
				let data = {
					fGoodsID: this.fGoodsID,
					fShopUnitID: this.listData.fShopUnitID,
					fAmount: this.numValue
				};
				obj.push(data);
				console.log('obj', obj);
				this.$store.commit("changeCommonData", obj);
				this.$router.push({
					name: 'FillOrderIntegral',
					params: {}
				})
			},
			//获取是否关注店铺
			getByShopunitID() {
				// IsFocus 0未关注，1已关注，Focus为关注数量
				this.ApiRequestPostNOMess('api/mall/auxiliary/focus-shop/get-by-shopunitID', {
						id: this.listData.fShopUnitID
					})
					.then(result => {
							////console.log('获取是否关注店铺', result);
							this.focusOnQuantity = result.obj.focus;
							if (result.obj.isFocus == 0) {
								this.followShow = true;
							} else {
								this.followShow = false;
							}
						},
						rej => {}
					)
			},
			//关注点击事件
			followCreateClick() {
				this.ApiRequestPostNOMess('api/mall/auxiliary/focus-shop/create', {
						fShopUnitID: this.listData.fShopUnitID
					})
					.then(result => {
							////console.log('关注点击事件', result);
							this.getByShopunitID();
							this.$message({
								message: '关注店铺成功！',
								type: 'success'
							});
						},
						rej => {}
					)
			},
			//取消关注事件
			followDeleteClick() {
				this.ApiRequestPostNOMess('api/mall/auxiliary/focus-shop/delete', {
						fShopUnitID: this.listData.fShopUnitID
					})
					.then(result => {
							////console.log('取消关注事件', result);
							this.getByShopunitID();
						},
						rej => {}
					)
			},
			getByShop(){
				this.ApiRequestPost('api/mall/ebshop/service-info/get-by-shop',{
					fShopID:this.listData.fShopUnitID
				}).then(result=>{
					this.fRulObj=result.obj
				})
			},
			//联系客服
			contactServiceClick() {
				window.open(this.fRulObj.fRul, '_blank')
			},
			//进店
			enterTheStore(id) {
				////console.log('id', id);
				this.$router.push({
					name: 'storeList',
					query: {
						id: id
					}
				})
			},
			//点击热销商品根据id重新加载页面
			hotGoods(obj) {
				//console.log('obj', obj);
				this.fGoodsID = obj.goods.fGoodsID;
				this.$router.push({
					name: "GoodsDetails",
					query: {
						fGoodsID: this.fGoodsID,
					},
				});
			},
			//tab点击事件
			handleClick(tab, event) {
				////console.log(tab, event);
			},
			//选择评价条件事件
			evaluateBtnClick(val) {
				for (let item of this.evaluateBtnData) {
					if (val == item.name) {
						item.checked = true;
					} else {
						item.checked = false;
					}
				}
			},
			//评价点赞事件
			thumbClick() {
				this.thumbActive = !this.thumbActive;
			},
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				////console.log(`每页 ${val} 条`);
			},
			//currentPage（当前页数） 改变时会触发
			handleCurrentChange(val) {
				////console.log(`当前页: ${val}`);
			},
			//询问弹框
			askhandleClose(){
				this.askdialogVisible = false
			},
			// 新增弹窗提交成功回调
			handleClose() {
				this.showModel = false;
			},
			// 新增验证事件
			submitForm() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						// this.addSubmit()
					} else {
						return false
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.top-box {
		min-height: 488px;
	}

	.box-left {
		display: flex;
		flex-direction: column;

		.outline-imgs {
			width: 349px;
			height: 349px;

			&::v-deep img {
				width: 100%;
				height: 100%;
			}

			.outline-img {
				width: 325px;
				height: 325px;
				border: 1px solid #f2f2f2;
				padding: 10px;
				border-radius: 10px;

				&::v-deep img {
					width: 100%;
					height: 100%;
					border-radius: 10px;
				}
			}

			.outline-piclist {
				width: 307px;
				height: 80px;
				overflow: hidden;
				margin: 10px 0;
				position: relative;
				padding: 0 20px;

				.piclist-box {
					cursor: pointer;
					position: absolute;
					left: 20px;
					top: 0;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
				}

				.left-arrow {
					z-index: 3;
					width: 27px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					background-color: #333333;
					opacity: 0.1;
					font-size: 20px;
					color: #fff;
					position: absolute;
					left: 0;
					top: 0;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					&:hover {
						opacity: 0.7;
						transition-property: opacity;
						transition-duration: 0.3s;
					}
				}

				.right-arrow {
					z-index: 3;
					width: 27px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					background-color: #333333;
					opacity: 0.1;
					font-size: 20px;
					color: #fff;
					position: absolute;
					right: 0;
					top: 0;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					&:hover {
						opacity: 0.7;
						transition-property: opacity;
						transition-duration: 0.3s;
					}
				}

				.piclist-img {
					width: 78px;
					height: 78px;
					margin-right: 10px;
					border: 1px solid #f2f2f2;

					img {
						width: 78px;
						height: 78px;
					}

					&:hover,
					&.active {
						border: 1px solid #f03333;
					}
				}
			}
		}
	}

	.tag-box {
		padding: 0 3px;
		background-image: linear-gradient(to right top, #FF0000, #FF9999);
	}

	.activity-box {
		width: 730px;
		height: 40px;
	}

	.box-right {}

	.commodityInformation-box {
		width: 704px;
	}

	.dashed {
		border-right: 1px dashed #FA2C60;
		margin: 0 2px;
	}

	.receive-box {
		border-bottom: 1px dashed #FA2C60;
	}

	.factory-box {
		width: 95px;
	}

	.factory-hover:hover {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.address-box {
		height: 50px;
	}

	.tatle-box {
		width: 65px;
	}

	.btn-box {
		width: 235px;
		height: 45px;
		line-height: 45px;
		box-sizing: border-box;
	}

	.btn-box1 {
		width: 725px;
		height: 45px;
		line-height: 45px;
		box-sizing: border-box;
	}

	.b-box-left {
		width: 260px;
		height: auto;
		box-sizing: border-box;
	}

	.img-box {
		width: 45px;
		height: 45px;
		box-sizing: border-box;
	}

	.img-style {
		width: 35px;
		height: 35px;
	}

	.follow-box {
		width: 60px;
		height: 24px;
		line-height: 24px;
	}

	.shopStarRating-box {
		width: 150px;
		height: 22px;
		line-height: 22px;
		box-sizing: border-box;
	}

	.el-rate::v-deep {
		height: 15px;

		.el-rate__icon {
			margin-right: 0;
			font-size: 15px;
		}
	}

	.shopBtn-box {
		width: 110px;
		height: 30px;
		line-height: 28px;
		box-sizing: border-box;
	}

	.left-middle {
		padding:17px;
		background-image: linear-gradient(to bottom, #9FC8FF, #D0F3FF);
		border-radius: 10px;
	}

	.b-box-right {
		width: 820px;
		min-height: 521px;
		height: auto;
	}

	.img-box1 {
		width: 107px;
		height: 107px;
	}

	.img-style1 {
		width: 90px;
		height: 90px;
	}

	.title-box {
		width: 107px;
	}

	.el-tabs--border-card::v-deep {
		background: #FFF;
		border: none;
		box-shadow: none;

		.el-tabs__header {
			background-color: #F8F9FE;
			border: 1px solid #f2f2f2;
			margin: 0;
		}

		.el-tabs__item:hover {
			@include themify($themes) {
				color: themed('themes_color');
			}
		}

		.is-active {
			@include themify($themes) {
				color: themed('themes_color');
			}

			background-color: #FFF;
			border-right-color: #f2f2f2;
			border-left-color: #f2f2f2;
		}

		.el-tabs__content {
			padding: 10px 0;
		}
	}

	.evaluateTitle-box {
		height: 63px;
	}

	.img-box2 {
		width: 35px;
		height: 35px;
		border-radius: 50%;
	}

	.img-style2 {
		width: 35px;
		height: 35px;
		border-radius: 50%;
	}

	.whole {
		width: 65px;
		height: 24px;
		line-height: 24px;
	}

	.evaluate-btn {
		height: 24px;
		line-height: 24px;
	}

	.shopRulesDialog::v-deep {
		.el-dialog__body {
			padding: 10px 20px 20px 20px;
		}
	}

	.el-input-number--mini::v-deep {
		line-height: 30px;
	}

	.delete-btn {
		top: 7px;
		right: 7px;
		z-index: 1000;
		color: #9e9e9e;
	}

	.play-img {
		width: 50px;
		height: 50px;
		background-image: url(../../../assets/imgs/goods/play1.png);

		&:hover {
			cursor: pointer;
			background-image: url(../../../assets/imgs/goods/play2.png);
		}
	}
	.askdialogScroll{
	  max-height: 25vh;
	  overflow-y:auto;
	  overflow-x:hidden;
	}
	#ask  /deep/ .el-dialog__body{
		padding:0px 15px
	}
	#ask  /deep/ .el-dialog{
		border-radius: 5px;
	}
	/deep/.el-input__inner{
		  height: 36px !important;
		  line-height: 36px !important;
		  border: 1px solid #DCDFE6 !important;
	}
	/deep/.el-input-number{
		  line-height: 34px !important;
	}
</style>
